/*----- font and body styles -----*/
/* comment out or override if you want to use existing page fonts */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#container {
	font: 14px/1.21 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
}

html, button, input, select, textarea, .pure-g [class *= "pure-u"] {
	font-family: 'Helvetica Neue', arial, sans-serif;
}



/*-----
    General CViTjs config
    #selector-app is the wrapper div for the entire
    cvit application
-----*/

h1 {
	text-align: center;
}

p {
    margin-top: 1rem;
}

html .container {
    font-size: 62.5%;
}


body .selector-container {
    font-size: 1.5em;
    line-height: 1.6;
    font-weight: 400;
    font-family: Raleway, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #222;
}

.container {
    margin: 0 auto;
    max-width: 80%;
}

.selector-container,
.selector-container .pure-g [class *= "pure-u"],
.selector-container button,
.selector-container html,
.selector-container input,
.selector-container select,
.selector-container textarea {
    font-family: Raleway, HelveticaNeue, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.selector-container .pure-g > div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.selector-container input[type="text"],
.selector-container textarea,
.selector-container select {
    height: 4rem;
    padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
}

.selector-container input[type="text"],
.selector-container textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.selector-container textarea {
    min-height: 65px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.selector-container input[type="text"]:focus,
.selector-container textarea:focus,
.selector-container select:focus {
    border: 1px solid #33C3F0;
    outline: 0;
}

.selector-container label,
.selector-container legend {
    display: block;
    margin-bottom: .5rem;
    font-weight: 600;
}


.selector-container label > .label-body {
    display: inline-block;
    margin-left: .5rem;
    font-weight: normal;
}


.selector-container th,
.selector-container td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #E1E1E1;
}

.selector-container th:first-child,
.selector-container td:first-child {
    padding-left: 0;
}

.selector-container th:last-child,
.selector-container td:last-child {
    padding-right: 0;
}

.selector-container h1,
.selector-container h2,
.selector-container h3,
.selector-container h4,
.selector-container h5,
.selector-container h6 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: 300;
}

.selector-container h1 {
    font-size: 4.0rem;
    line-height: 1.2;
    letter-spacing: -.1rem;
}

.selector-container h2 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: -.1rem;
}

.selector-container h3 {
    font-size: 3.0rem;
    line-height: 1.3;
    letter-spacing: -.1rem;
}

.selector-container h4 {
    font-size: 2.4rem;
    line-height: 1.35;
    letter-spacing: -.08rem;
}

.selector-container h5 {
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: -.05rem;
}

.selector-container h6 {
    font-size: 1.5rem;
    line-height: 1.6;
    letter-spacing: 0;
}


/*----- Dummy button formatting -----*/
.button-display, .button-action {
    text-align: center;
    font-size: .75em;
    font-weight: 600;
    line-height: 2.5em;
    letter-spacing: .1em;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #888;
    color: #333;
    cursor: pointer;
    box-sizing: border-box;
}

.button-action {
    color: #fff;
}

.fake-button {
    display: inline-block;
    height: 38px;
    padding: 5px 30px 0;
    text-align: center;
    font-size: .75em;
    font-weight: 600;
    line-height: 2.5em;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #888;
    color: #333;
    cursor: pointer;
    box-sizing: border-box;
}

/* full button text label*/
.l-box {
    padding: .25rem;
}

/* ^ v marker rotate animation */
.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin-top: .5rem;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(0deg);
    transition: transform .2s ease-out;
}

.arrow-down.rotate {
    transform: rotate(180deg);
}



/*----- Genotype and Display controls -----*/

.genotype-field {
    border-width: 1px;
    border-style: solid;
    border-color: dimgrey;
    padding: 0.5em 1em;
}

.select__input {
    max-height: 1rem;
}

.git-option {
    margin-top: .25rem;
    margin-bottom: 1.25rem;
    height: 4.5rem;
}

.git-option-component:hover {
    background-color: #2684ff;
}

.genotype-select {
    width: 100%;
    padding-bottom: 0.5rem;
}


legend {
    margin: 0.5em;
    text-align: left;
}


.remove-genotype {
    margin-top: 2em;
    text-align: center;
}

.filter-drop {
}

.display-options {
    transition: all .1s ease-in-out;
    margin-bottom: .5rem;
    z-index: 1000;
}

.header-image {
    float:left;
    margin-top:1em;
    margin-bottom:.5em;
}

.header-container{
    float:left;
    margin-left:.5em;
    margin-right:1em;
    margin-top:.5em;
    margin-bottom:.5em;
    font-family:ProximaNova,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
}

.header-title{
    font-size:1.5em;
    color:black;
}

.header-caption{
    font-size:.9em; 
    color:black;
}


/*----- Modals and Contents -----*/

.help-text {
    transition: max-height .1s ease-in-out;
    overflow-y: auto;
    margin: 0 1rem 0 1rem;
}

.modal-Section {
    height: 90%;
}

h6 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modal-area {
    height: 100%;
    overflow: hidden;
}

.modal-content {
    overflow: hidden;
    position: fixed;
    top: 4em;
    left: 4em;
    right: 4em;
    bottom: 9em;
    font-size: 1.5rem;
}

.modal-contents {
    overflow-y: auto;
    position: fixed;
    top: 11em;
    left: 4em;
    right: 4em;
    bottom: 9em;
    width: 80%;
    margin: auto;
}

.modal-close {
    position: fixed;
    bottom: 4em;
    font-size: 1.5rem;
}

/* - loading for data donwload -*/
.gcvit-load-text {
    display: none;
 }


.gcvit-load-text.download-loading{
   display:block;
   color: white;
   border-radius: 4px;
   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
   background: rgb(66, 184, 221);
   padding: 1em;
}

.download-loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;    
  }
}

.key-color {
        white-space : pre;
        display : inline;
        border : 1px solid #888;
        border-radius : 4px;
}

